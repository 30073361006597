/* X-Small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap*/
@media (max-width: 575.98px) {
    body{
        overflow: hidden;
    }
    .main > .container-fluid {
        background-color: #f9fafb;
        padding:0!important;
        height: calc(100vh - 80px);
        overflow-y: auto;
    }
    header{
        background: #ffffff;
    }
    .content > .channel-header {
        padding: 32px 32px 2px 32px;
    }
    .content{
        padding: 0 !important;
    }
    .channel-header .breadcrumb-item.active{
        display: none;
    }
    .invitations-list > li{
        padding: 16px 4px!important;
        justify-content: space-between;
    }
}
/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 767.98px) {


}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 991.98px) {

}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 1199.98px) {

}

/* X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}

