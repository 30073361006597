/*html, body {*/
/*    overflow: hidden;*/
/*    scroll-behavior: smooth;*/
/*}*/

/*::-webkit-scrollbar {*/
/*    width: 5px;*/
/*    display: none;*/

/*}*/

/*::-webkit-scrollbar-track {*/
/*    !*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*!*/
/*    border-radius: 10px;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*    border-radius: 10px;*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);*/
/*}*/
.main {
    height: 100vh !important;
}

.items-center{
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.sidebar {
    width: 320px;
    padding: 32px 16px;
    border-right: 1px solid #eaecf0;
}

.sidebar .offcanvas-body {
    display: contents;
}

.sidebar .top {
    height: 40px !important;
    margin-bottom: 24px;
}

.sidebar .top>.sidebar-buttons {
    display: flex;
}

.sidebar .top>.sidebar-buttons .icon {
    display: flex;
    height: 40px !important;
    width: 40px !important;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
}

.sidebar .top>.sidebar-buttons>.icon:hover {
    background-color: rgba(30, 43, 59, 0.15);
    border-radius: 6px;
}

/*.sidebar > .top > .sidebar-buttons > .icon >  .badge{*/
/*    background-color: rgba(255, 155, 1, 1);*/
/*    border:2px solid white;*/
/*}*/

.divider {
    width: 100%;
    height: 1px !important;
    opacity: 0.4;
    background-color: rgba(30, 43, 59, 0.20) !important;
    margin: 10px 0;
}

.sidebar .sidebar-element {
    padding: 0;
}

.sidebar .sidebar-element>.create-channel-link {
    text-decoration: none;
    color: #344054;
    font-size: 18px;
    align-items: center;
    display: flex;
    line-height: 24px;
    padding: 8px;
}

.sidebar .sidebar-element>.create-channel-link:hover {
    background-color: rgba(30, 43, 59, 0.15);
    border-radius: 8px;
    transition: ease-in 0.2s ease-out 0.2s;
}

.sidebar .sidebar-element>.create-channel-link>span {
    margin-right: 8px;
}

.dropdown-toggle::after {
    display: none;
}

.profile.dropdown {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}

.profile.dropdown a {
    text-decoration: none;
    width: 100%;
    max-width: 100% !important;
}

.profile.dropdown a .initals {
    background-color: #f9fafb;
    height: 40px !important;
    width: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #475467;
    border-radius: 50%;
    padding: 4px;
    margin-right: 14px;
}

.profile.dropdown a .icon {

    height: 40px !important;
    width: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;

}

.profile.dropdown a>.profile-info>.details .name {
    color: #344054;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 500;
}

.profile.dropdown a>.profile-info>.details .email {
    color: #667085;
    font-size: 14px;
    white-space: nowrap;
}

.dropdown-menu {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
    border: solid 1px #f2f4f7;
    padding: 0;
}

.dropdown-menu li a {
    height: 44px;
    padding: 10px 14px;
    color: #101828;
    font-size: 16px;
}

.dropdown-menu li a:hover {
    background-color: #f9fafb;
}

.channels-list {
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
}

.channels-list .channel-item>a {
    color: #344054;
}

.channels-list .channel-item a {

    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    transition: ease 0.4s;
}

.channels-list .channel-item a:hover,
.channels-list .channel-item.active a {
    background-color: #f1f1f1;
    transition: ease 0.4s;
}

.channels-list .channel-item.inactive a {
    background-color: #CFD2CF;
}

.channels-list .channel-item a .logo {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.channels-list .channel-item a .logo img {
    width: 40px;
    border-radius: 50%;
}

.channels-list .channel-item a .title {
    width: auto;
    white-space: nowrap;
    color: #344054;
    display: grid;
}

.channels-list .channel-item a .title>.subtitle {
    font-size: 12px;
    color: #636a7e;
}

.badge.beepy-light {
    background-color: rgb(255, 235, 204);
    color: #1e2b3b;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
}

.main>.container-fluid {
    background-color: #f9fafb;
    padding: 32px
}

.container-fluid .content {
    align-self: stretch;
    flex-grow: 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.1);
    border: solid 1px #eaecf0;
    background-color: #fff;
    height: 100%;
    overflow: hidden;


}

.container-fluid .content .title {
    align-self: stretch;
    flex-grow: 0;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #101828;
}

.container-fluid .content .subtitle {
    align-self: stretch;
    flex-grow: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #667085;
}

.btn.beepy-lg {
    width: 300px;
    background-color: #ff9b01;
    padding: 12px;
    color: #ffffff;
}

.content>.channel-header {
    padding: 32px 32px 2px 32px;
}


.channel-header .channel-logo {
    width: 64px;
    height: 64px;
    margin-right: 20px;
    object-fit: contain;
    background-color: #f1f1f1;
    border-radius: 8px;
}

.channel-header .channel-logo img {
    width: 64px !important;
    height: 64px !important;
    border-radius: 8px;
}

.channel-header .channel-title {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #101828;
}

.channel-header .channel-title .channel-slug {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #667085;
}

.channel-header .btn.header-button {
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border: solid 1px #d0d5dd;
    background-color: #fff;
}

.channel-header .btn.header-button:hover {
    background-color: #f1f1f1;
}

.profile-body {
    overflow-y: auto;
    position: relative;
    height: calc(100% - 250px) !important;
    padding: 0rem 1.5rem 1.5rem 1.5rem; 
}

.channel-body {
    overflow-y: auto;
    position: relative;
    height: calc(100% - 150px) !important;
    padding: 0rem 1.5rem 1.5rem 1.5rem; 
}

.connection.btn {
    padding: 6px 12px;
    border-radius: 10px;
    margin-bottom: 12px;
}

.connection.btn svg {
    width: 48px !important;
    height: 48px !important;
}

.connection.btn .title {
    font-size: 17px;
    display: grid;
    text-align: start;
    line-height: 1.2;
    color: #ffffff;
    margin-left: 1rem;
}

.connection.btn .title span {
    font-size: 10px;
    line-height: 2;
    color: rgba(255, 255, 255, 0.8);
}

.connection.btn.github {
    background-color: #000000;

}

.connection.btn.slack {
    background-color: #611f69;
}

.connection.btn.aws {
    background-color: #04273a;
}

.connection.btn.jira {
    background-color: #0052cc;
}

.connection.btn.beepy {
    background-color: #1a1824;
}

.link-beepy {
    color: #ff9b01;
    text-decoration: none;
}

.link-beepy:hover {
    color: #ef9100;
}

.notifications-list {
    display: flex;
    flex-direction: column-reverse;
    list-style: none;
    padding: 16px;
    overflow-y: auto;
    scroll-behavior: smooth !important;
    position: relative;
    height: calc(100vh - 190px) !important;
}

.notifications-list li {
    /*max-width: 70%;*/
    margin-bottom: 16px;
}

.notifications-list li .content {

    background-color: #f1f2f6;
    border-radius: 8px;
    padding: 8px;
    box-shadow: none;
    border: 0px;
    height: auto;
}

.notifications-list li .title {
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1a1824 !important;
}

.notifications-list li .description {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #1a1824;
}

.notifications-list li .show-more {
    font-size: 12px;
    color: #bbb;
    cursor: pointer;
}

.notifications-list li .bottom {
    margin-top: 12px;
    display: flex;
    align-items: center;

    width: 100%;
}

.notifications-list li .bottom .tag {
    background-color: #bbb;
    border-radius: 4px;
    padding: 2px 4px;
    color: #ffffff;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
}

.notifications-list li .bottom .date {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: #667085;
}

.notifications-list li .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notifications-list li .footer .data {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    letter-spacing: normal;
    text-align: left;
    color: #667085;
}

.notifications-list li .footer .actions {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    letter-spacing: normal;
    text-align: left;
    color: #667085;

}

.notifications-list li .footer .actions>a {
    text-decoration: none !important;
    margin-left: 12px;
}

.notifications-list li .footer .actions>a.light {
    color: #667085;
}

.notifications-list li .footer .actions>a.red {
    color: #d0021b;
}

/* Invitations List */
.striped-list>li:nth-of-type(odd) {
    background-color: #f9fafb;
}

.invitations-list {
    list-style: none;
    padding: 5px;
    border-radius: 8px;
    height: calc(100vh - 260px);
}

.invitations-list>li {
    padding: 16px 24px;
}


.invitations-list li:first-child {
    border-radius: 10px 10px 0 0;
}

.invitations-list li:last-child {
    border-radius: 0 0 10px 10px;
}

.invitations-list li img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 14px;
}

.invitations-list li .details .title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #344054;
}


.invitations-list li .details .subtitle {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #667085;
}

.invitations-list li .actions img {
    width: 18px;
}

.invitations-list li .actions .actions-buttons {
    cursor: pointer;
}

.pricing-list {
    list-style: none;
    padding: 5px;
    border-radius: 8px;
}

.pricing-list>li {
    padding: 16px 24px;
}

.pricing-list li:first-child {
    border-radius: 10px 10px 0 0;
}

.pricing-list li:last-child {
    border-radius: 0 0 10px 10px;
}

.pricing-list li img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 14px;
}

.pricing-list li .details .title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #344054;
}


.pricing-list li .details .subtitle {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #667085;
}

.pricing-list li .actions img {
    width: 18px;
}

.pricing-list li .actions .actions-buttons {
    cursor: pointer;
}

.pricing-list-head {
    list-style: none;
    padding: 5px;
    border-radius: 8px;
}

.pricing-list-head>li {
    padding: 16px 24px;
}

.pricing-list-head li:first-child {
    border-radius: 10px 10px 0 0;
}

.pricing-list-head li:last-child {
    border-radius: 0 0 10px 10px;
}

.channel-header .breadcrumb {}

.channel-header .breadcrumb-item {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #667085;
    cursor: default;
}

.channel-header .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: none;
}

.channel-header .breadcrumb .breadcrumb-divider {
    margin: 0 4px;
    background: url("../images/icons/chevron-right.svg");
    width: 14px;
    height: 14px;
}

.channel-header .breadcrumb-item.active {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #1e2b3b;
    background-color: #f1f1f1;
    padding: 4px 8px;
    border-radius: 6px;
    cursor: default;
}

.channel-settings-menu {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #eaecf0;
    list-style: none;
    padding: 0;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
}

.channel-settings-menu::-webkit-scrollbar {
    display: none;
}

.channel-settings-menu li {
    margin-right: 24px;
    padding: 16px 2px;
    border-bottom: 2px solid #fff;
}

.channel-settings-menu li.active,
.channel-settings-menu li:hover {
    border-bottom: 2px solid #1e2b3b;

}

.channel-settings-menu li.active a,
.channel-settings-menu li a:hover {
    color: #1e2b3b;
}

.channel-settings-menu li a {
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #667085;
}

.channel-settings-menu li a img {
    width: 16px;
    height: 16px;
    margin-right: 8px;

}

.messages-side {
    border-right: 1px solid  #eaecf0;
    width: 600px;
    max-width: 600px!important;
    padding:16px;
    height: 100vh;
    overflow-y: auto;
}

.messages-list{
    list-style: none;
    padding: 8px 0;
    max-width: calc(600px - 32px);
}
.messages-list li{
    border-bottom: 1px solid #eaecf0;
    padding: 8px 8px;
    margin-bottom: 8px;
}

.messages-list li.unread{
    background-color: rgb(255,243,241);
    border-radius: 4px;
}
.messages-list li:last-child{
    border-bottom: 0px;
}

.messages-list li:hover, .messages-list li.active{
    background: #f1f1f1;
    border-radius: 4px;
}
.messages-list li .details{
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}
.messages-list li .details .tag{
    font-size:11px;
    line-height: 18px;
    border-radius:10px;
    padding: 2px 4px;
    color:#fff;
}
.messages-list li .details .date{
    color:#8290b0;
    font-size:12px
}

.messages-list li .title{
    color:#1e2b3b;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;

}
.messages-list li .description{
    color:#415e81;
    font-size:14px;
}


.message-details{
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: auto;
}

.message-details .details{
    display:flex;
    justify-content: space-between;
}

.message-details  .details .tag{
    font-size:11px;
    line-height: 18px;
    border-radius:10px;
    padding: 2px 4px;
    color:#fff;
}
.message-details  .details .date{
    color:#8290b0;
    font-size:12px
}

.message-details .title{
    color:#1e2b3b;
    font-weight: bold;
    margin-top:16px;
    margin-bottom: 4px;
    text-align: start!important;

}
.message-details .description{
    color:#415e81;
    font-size:14px;
}


.form-check-input:checked {
    background-color: black;
    border-color: transparent;
}

.form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='black'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.hoverDiv {
    background: transparent;
}

.hoverDiv:hover {
    background: #f1f1f1;
}

.alert-black {
    background-color: #1e2b3b;
    color: #fff;
}

.alert-black a {
    color: #fff;
    text-underline-offset: 1.5px;
}

.beepy-invoice-table {
    padding: 1rem;
}

.beepy-invoice-table thead {
    background-color: #f9fafb;
    color: #757d8e;
}

.beepy-invoice-table td {
    color: #667085;
}

.beepy-invoice-table tbody {
    padding: 3rem!important;
}