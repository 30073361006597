/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.Toastify__toast-container {
  width: max-content!important;
  max-width: 50%!important;
  margin-bottom: 0px!important;
}

/* under 768px */
@media only screen and (max-width: 768px) {
  .Toastify__toast-container {
    width: max-content!important;
    max-width: 90%!important;
    margin-bottom: 0px!important;
    margin-left: 5%!important;
  }
}

.alertBody {
  margin: .25rem!important;
}

.bg--beepy-grey {
  background-color: #f1f1f1;
}

.bg--beepy-green {
  background-color: #12b76a;
}

.bg--beepy-light-green {
  background-color: #ecfdf3;
}

.color--beepy-dark-green {
  color: #027a48!important;
}

.bg--primary {
  background-color: #ff9b01!important;
}

.text--primary {
  color: #ff9b01!important;
  text-decoration: none!important;
}

.fill--primary {
  fill: #ff9b01!important;
}

.bg--beepy-dark {
  background-color: #1e2b3b!important;
}

.color--beepy-dark {
  color: #1e2b3b!important;
}

.color--beepy-dark-grey {
  color: #667085!important;
}

.beepy-input-border {
  border: solid 1px #d0d5dd;
}

.beepy-title-color {
  color: #101828!important;
}

.beepy-subtitle-color {
  color: #344054!important;
}


.stepwizard-step p {
  margin-top: 0px;
  color:#666;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}
.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content:" ";
  width: 100%;
  height: 2px;
  background-color: #ccc;
  z-index: 0;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.color--beepy-grey {
  color: #7992b0!important;
}

.bg--beepy-secondary-button {
  background-color: #dedfe2!important;
}

/* AUTH */

.authBody {
    text-align: center;
    font-family: 'Inter', sans-serif;
}

.authContainer {
    margin-top: 100px;
}

.logoAuth {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

#btnLogin {
    background-color: #1e2b3b;
}

#loginModal {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 10px;
    box-shadow: 0px 1px 3px #94979d;
}

#inputPassword {
    margin-bottom: 20px;
}

#btnLogin {
    background-color: #1e2b3b;
    margin-top: 20px;
    font-size: 15px;
    border: none;
}

#singinTitleh3 {
    font-weight: bold;
}

.smallTitle {
    color: #94979d;
}

.inputText {
    text-align: left;
    font-size: 12px;
    margin-top: 20px;
    font-weight: bold;
}

#forgotPass {
    color: #eb9c01;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

#bottomTextDiv {
  margin-top: 1rem;
    text-decoration: none;
}

#smallTextLeft {
    color: #94979d;
}

#smallTextRight {
    color: #eb9c01;
}

.block {
    height: 20px;
}

.pMargin {
    margin: 0px;
}

/* new css */

.header h3 {
    font-weight: bold;
}

.header small {
    color: #94979d;
}

.pageModal {
    background-color: #fff;
    padding-top: 0.75rem;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 0.75rem;
    box-shadow: 0px 1px 3px #94979d;
}

.pageModal p {
    color: #667085;
    margin-top: 24px;
    font-size: 14px;
}

.pageModal a {
    color: #ff9b01;
}

.pageModal button {
    background-color: #1e2b3b;
    color: white;
    height: 2.75rem;
    width: 100%;
    margin-top: 1.25rem;
    font-size: 15px;
    border: none;
    cursor: pointer;
}

.pageModal button:hover {
    background-color: #171f29;
    color: white;
}

.modalBody .modalBodyForm {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.modalBody .modalBodyForm input {
    width: 3rem;
    height: 3rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 10px;
    text-align: center;
    flex-grow: 1;
    font-size: 20px;
    font-weight: 500;
    color: #1e2b3b;
}

.modalBody .modalBodyForm span {
    font-size: 2rem;
    color: #d0d5dd;
    text-align: center;
}

.modalBody small {
    color: #94979d;
}

.modalBody h4 {
    text-align: left;
    font-size: 12px;
    margin-top: 20px;
    font-weight: bold;
}

/* animation */

.animated {
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInLeft {
  0% {
     opacity: 0;
     -webkit-transform: translateX(-20px);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
     opacity: 0;
     transform: translateX(-20px);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
}

.fadeInLeft {
-webkit-animation-name: fadeInLeft;
animation-name: fadeInLeft;
}

.spinner {
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.channelCategories {
  height: 45vh!important;
  overflow-y: auto;
}

.subscription-feature {
  color: #181059;
}

.subscription-feature.unavailable {
  opacity: 0.3;
}

.subscription-feature.description {
  color: #8290b0!important;
}

.subscription-feature.list {
  padding-left: 0px!important;
}

.subscription-feature.feature {
  padding-left: .5rem!important;
}

.subscription-feature.price {
  font-size: 25px;
  font-weight: bold;
}

.subscription-feature.cycle {
  opacity: .68;
}

.cursor-pointer {
  cursor: pointer!important;
}

.profile-username {
  margin-bottom: 1px!important;
}

.check:checked::before {
  background-image: url('./assets/images/icons/check-black.svg')!important;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  display: block;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: white;
}

.check input[type="radio"] {
  display: none!important;
}

.check {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: white;
}

.categoryElement:hover {
  background-color: #f5f5f5;
}